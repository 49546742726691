// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
}

body {
    direction: ltr;
    font-family: $text-font-stack;
    font-size: 16px;
    color: $gray;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

h1, .h1 {
	margin: 0;
}
h2, .h2 {
	font-size: 24px;
}

a {
	color: $blue;
}

.wrapper {
    margin: 0 auto;
	
	width: 100%;
    max-width: 1170px;
    
    position: relative;
}

.mt {
	margin-top: 72px;
}

.mt-half {
	margin-top: 31px;
}

.blue-txt {
	color: $blue;	
}

.titile-middle {
	margin: 30px 0 20px 0;
	font-size: 18px;
}

.section-title {
	position: relative;
	margin-bottom: 40px;
	padding-top: 10px;
	font-size: 24px;
	line-height: 24px;
	color: $gray;
	@media (max-width: $screen-sm-max) {
		font-size: 20px;
		line-height: 20px;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 55px;
		height: 5px;
		display: block;
		background: $section-tile-blue;
	}

}