//footer styles

.footer-first {
	padding: 20px 0 25px;
	border-top: 1px solid $gray-border;
	border-bottom: 1px solid $gray-border;

	background: url("../img/footer-bg.png") top center no-repeat;
	background-color: $footer-light;

	.prod-title {
		margin-top: 0;
		color: $blue;
		font-size: 18px;
	}

	.prod-info {
		margin-top: 15px;
		@media (min-width: $screen-sm-max) {
			margin-top: 0;
		}
		p {
			font-size: 15px;
		}
	}

	.list-items-wrapper {
		font-size: 14px;
	}
}

.footer-second {
	padding: 40px 0 20px;
	background-color: $footer-dark;
	
	.col-sm-6 {
		margin-bottom: 15px;
		@media (min-width: $screen-sm-max) {
			min-height: auto;
		}
		@media (min-width: $screen-xs-max) {
			min-height: 188px;
		}
	}

	.footer-social-navigation {
		margin-top: 15px;
		margin-left: 27px;

		a {
			margin-right: 5px;
			font-size: 30px;
			color: $gray-light;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: none;
				outline: none;
			}
			&.icon-facebook {
				&:hover {
					color: $facebook-blue;
				}
			}
			&.icon-twitter {
				&:hover {
					color: $twitter-blue;
				}
			}
			&.icon-linkedin {
				&:hover {
					color: $linkedin-blue;
				}
			}
			&.icon-google-plus {
				&:hover {
					color: $google-plus-red;
				}
			}
		}
	}

	.address-item {
		span,
		strong,
		.telephone,
		.email {
			display: block;
			font-size: 14px;
		}
		strong {
			margin-bottom: 5px;
			font-size: 16px;
		}
		span {
			margin-bottom: 10px;
		}		
		.email {
			margin-top: 10px;
			color: $gray;
		}

	}
	.quick {
		display: block;
		margin-bottom: 5px;
	}
	.site-map {
		margin: 0;
		padding: 0;

		li {
			list-style-type: none;

			a {
				color: $gray;
				font-size: 16px;
			}
		}
	}

	.microsoft-partner-img {
		display: block;
		margin-bottom: 20px;
	}

	.logo-iso {
		margin-bottom: 10px;
			.pull-left {
				margin-left: 15px;
				float: none!important;
			}
		@media (min-width: $screen-xs-max) {
			.pull-left {
				float: left!important;
			}
		}
		span {
			font-size: 13px;
		}

		img {
			display: block;
			width: 57px;
		}
	}
}

.footer-copyright {
	padding: 10px 0;

	color: $white-darken;
	font-size: 12px;
	text-align: center;

	background-color: $dark;
}

.logos {
	&:hover {
		text-decoration: none;
	}
}

.logo-sbnd, 
.part-of,
.logo-prime {
	display: block;
	color: $dark;	
}

.part-of {
	margin: 2px 0 5px 22px;
	font-weight: 600;	
}

.logo-prime {
	margin-left: 10px;
}