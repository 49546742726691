//hero section styles

.hero-header {
	margin-top: 72px;
	padding: 60px 0;
	width: 100%;

	position: relative;

	color: $white;

	h1 {
		text-transform: uppercase;
		font-size: 36px;
		word-wrap: break-word;


		span {
			display: block;
			font-size: 24px;
		}
	}

	p {
		margin-bottom: 20px;
	}
}


.home {
	.hero-header {
		min-height: 640px;

		background: url("../img/hero-higher.png") center center no-repeat;
		background-size: cover;
	}
}

.about {
	.hero-header {
		min-height: 640px;

		background: url("../img/hero-higher-about.png") center center no-repeat;
		background-size: cover;
	}
}

.solutions {
	.hero-header {
		min-height: 640px;

		background: url("../img/hero-higher-services.png") center center no-repeat;
		background-size: cover;
	}
}

.pricing-page {
	.hero-header {
		min-height: 270px;
		
		background: url("../img/hero-higher-pricing.png") center center no-repeat;
		background-size: cover;
	}
}

.customers {
	.hero-header {
		min-height: 270px;

		background: url("../img/hero-higher-portfolio.png") center center no-repeat;
		background-size: cover;
	}
}

.contact {
	.hero-header {
		min-height: 270px;

		background: url("../img/hero-higher-contact.png") center center no-repeat;
		background-size: cover;
	}
}

.approach {
	.hero-header {
		min-height: 270px;

		background: url("../img/hero-higher-approach.png") center center no-repeat;
		background-size: cover;
	}
}

.forgottenpassword,
.customerlogin {
	.hero-header {
		min-height: 270px;

		background: url("../img/hero-higher-login.png") center center no-repeat;
		background-size: cover;
	}
}

.sbndcatalog {
	.hero-header {
		min-height: 270px;

		background: url("../img/hero-higher-sbndcatalog.png") center center no-repeat;
		background-size: cover;
	}
}