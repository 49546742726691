// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Font variables

/// Regular font family
/// @type List
$text-font-stack: 'HelveticaNeue', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$french-font-stack: 'Roboto Condensed', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'liberatorregular', 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Copy text color
/// @type Color  


/// Main brand color
/// @type Color



/// White
/// @type Color
$white: 		  #FFF !default;
 
/// Black
/// @type Color
$black: 		  #000 !default;


/*
 * colors
 */
$white-darken:  #e3e3e3;
$white-light:  	#f6f6f6;
$blue: 			#00a1e1;
$gray-light: 	#989898;
$gray-border: 	#d1d1d1;
$gray: 			#696969;
$dark: 			#373737;
$red: 			#da0000;
$green:			#228d10;
$dark-light: 	#3e3934;
$footer-light: 	#f6f6f6;
$footer-dark: 	#ededed;
$main-gray: 	#f7f7f7;
$slide-gray: 	#d7d7d7;



// Extra small screen / phone
$wrapper-xs:		100%;

// Small screen / tablet
$wrapper-sm:		720px;

// Medium screen / desktop
$wrapper-md:		970px;

$wrapper-lg:		1170px;

// Media desktop small
$screen-md-max:		((1200px + $grid-gutter-width));

// Media tablet
$screen-sm-max: 	1023px;

// Media phone
$screen-xs-max: 	767px;


//section title border
$section-tile-blue: $blue;

//lists 
$list-prefix-dash-color: 		$blue;
$list-underline-color: 			$white-darken;


//social media

$facebook-blue:		#3b5998;
$twitter-blue: 		#00aced;
$linkedin-blue:		#0077b5;
$google-plus-red: 		#d34836;

/// Container's maximum width
/// Below that is 100%
/// @type Length
$max-width: 1400px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
  'extra-small': (max-width: 320px),
  'small': (max-width: 480px),
  'medium': (max-width: 768px),
  'large': (max-width: 1024px),
  'extra-large': (max-width: 1280px)
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN

///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '' !default;

///   $base-url: 'http://cdn.example.com/assets/img/responsive/some_image.png';
$images-url: $base-url + '/img/' !default;
$fonts-url: $base-url + '/fonts/' !default;

/// Nav item widths
$nav-width: 100%;


