// general btn

.btn-border,
.btn-main {
	margin: 15px 10px 15px 0; 
	padding: 7px 13px;
	min-width: 170px;

	font-size: 14px;
	display: inline-block;
	vertical-align: middle;

	&:focus {
		text-decoration: none;
	}


	@include borderRadius(50px);
	border: 1px solid transparent;
	
	span,
	svg {
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
	}

	&.white-bg {
		color: $blue;
		background-color: $white;
			border: 1px solid $gray-border;
		
		&:hover {
			text-decoration: none;
			color: $white;
			background-color: $blue;
		}
	}
}

.btn-main {
	color: $white;
	background-color: $blue;
	
	&:hover {
		border: 1px solid $gray-border;
		text-decoration: none;
		color: $blue;
		background-color: $white;
	}
}

.btn-border {
	color: $gray;
	background-color: $white-light;
	border: 1px solid $gray-light;
	
	&:hover {
		text-decoration: none;
		color: $blue;
		background-color: $white;
	}
}

.hero-header {
	.btn-main {
	
		color: $blue;
		background-color: $white;
		border: 1px solid transparent;
		&:hover {
			border: 1px solid transparent;
			color: $white;
			background-color: $blue;
		}
	}
}

