// general form
.form-control {
	background-color: $white;
	&:focus {
		background: $white;
		outline: none;
	}
}

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: $white;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}


.cat_item ul {
	list-style-type: none;
	padding:0;
	margin:10px 0;
	border-top:1px solid $table-border-color;
}
.cat_item ul li {
	padding: 10px 0;
	border-bottom:1px solid $table-border-color;
}

.form-group {
	input[type="text"] {
		width: 100%;
	    height: 34px;
	    padding: 6px 12px;
	    border: 1px solid #ccc;
	    border-radius: 4px;
	    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	}
}