// general Timeline 
.page-footer,
.page-header {
    width: 60%;
    margin: 15px auto;
    border: 0;

    position: relative;

    p,
    .blue-txt {
        text-align: center;
    }
    .blue-txt {
        line-height: 37px;
    }
}
.page-header {
    .timeline-badge{
        top: inherit;
        bottom: -23px;
    }
}

.page-footer {
    .timeline-badge{
        top: -34px;
    }
}

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
}

.timeline > li {
    margin-bottom: 0;
    position: relative;
    
    @media (min-width: $screen-sm-max) {
        margin-bottom: 20px;
    }
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-img,
.timeline > li > .timeline-panel {
    width: 46%;
    float: left;
    
    padding: 20px;
    position: relative;
    text-align: right;
    
}

.timeline > li > .timeline-img {
    text-align: left;
        margin-left: 60px;
    width: 33%;

    @media (min-width: $screen-sm-max) {
        margin-left: 90px;
    }
}

.timeline-badge {
    margin-left: -13px;
    width: 25px;
    height: 25px;
    
    line-height: 25px;
    font-size: 1.4em;

    position: absolute;
    top: 16px;
    left: 50%;
    z-index: 100;
    
    color: $white;
    text-align: center;
    background-color: $white;
    border: 1px solid $gray;
    
    @include borderRadius(50%);

    &:before {
        content: "";
        
        width: 15px;
        height: 15px;

        
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 99;
        
        background-color: $blue;
        @include borderRadius(50%);
    }
}

.timeline > li.timeline-inverted > .timeline-img {
    float: left;
    text-align: right;
    width: 38%;
}
.timeline > li.timeline-inverted > .timeline-panel {
    text-align: left;
    float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}


.timeline-title {
    margin-top: 0;
    margin-bottom: 15px;
    color: inherit;
    color: $blue;
    font-size: 24px;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

    .timeline-body > p + p {
        margin-top: 5px;
    }

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 45px);
        width: -moz-calc(100% - 45px);
        width: -webkit-calc(100% - 45px);

    }

    .page-footer,
    .page-header {
        width: calc(100% - 89px);
        width: -moz-calc(100% - 89px);
        width: -webkit-calc(100% - 89px);

    }

    .timeline-badge {
        left: 15px;
        margin-left: 13px;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
        text-align: left;
    }
    
    .timeline > li > .timeline-img {
        display: none;
    }
    
    .page-footer,
    .page-header {
        padding-left: 23px;
        border: 0;

        position: relative;


        p,
        .blue-txt {
            text-align: left;
        }

        .timeline-badge{
            margin: 0;
            top: 0;
            left: -17px;
        }
    }

    .page-header {
        .timeline-badge {
            &:after {
                height: 500px;
                top: 25px;
                bottom: 0;
                position: absolute;
                content: " ";
                width: 3px;
                background-color: #eeeeee;
                left: 50%;
                margin-left: -1.5px;
            }
        }
    }
    .page-footer {
        margin-top: -20px;
    }
}