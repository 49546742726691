//COMON STYLES FOR ALL PAGES
.main-info {
	// padding-bottom: 50px;
	width: 100%;
	
	background: url("../img/main-bg.png") top center no-repeat;
	background-size: 100%;
	background-color: $main-gray;

	position: relative;
}

.over-section {
	margin: 20px 0;
	position: relative;
	top: 0;

	width: 100%; 
	padding: 20px;
 
	background-color: $white;
	@include borderRadius(20px);

	p {
		margin-bottom: 20px;
	}

	@media (min-width: $screen-sm-max) {
		margin: 0;
		top: -106px;
		padding: 40px;
		margin-bottom: -58px;
	}
}

.list-items-wrapper {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		color: $black;
		&:before {
		    /* Unicode bullet symbol */
		    content: '\2022 ';
		    /* Bullet color */
		    vertical-align: middle;
		    color: $list-prefix-dash-color;
		    padding-right: 0.5em;
		}
	}
}

.solutions {
	.custom-slider-wrapper,
	.hero-header {

		.list-items-wrapper { 
			li {
				color: $white!important;
				&:before {
			    	color: $white;
				}
			}
		}
	}

}

.secondary-info {
	
	margin: 0;
	@media (min-width: $screen-sm-max) {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 20px;
	}
	

	.sub-title-blue {
		margin-bottom: 5px;
		font-size: 18px;
		line-height: 24px;
		color: $blue;
	}
}

.main-contact {
	margin-top: 20px;
	margin-bottom: 10px;

	.town,
	.telephone {
		display: block;
		margin-bottom: 5px;
	}

	.telephone {
		margin: 15px 0;
		color: $gray;
	}
}

.img-holder {
	img {
		width: 100%;
		@media (min-width: $screen-xs-max) {
			width: auto;
		}
	}
}

.span4.home3col {
		margin-bottom: 0;
	@media (min-width: $screen-sm-max) {
		margin-bottom: -90px;
	}
}

.girl-wrapper {
    	display: none;
    @media (min-width: $screen-sm-max) {
		position: absolute;
	    right: 0;
	    top: -367px;
		display: block;
    }
}

.bg-height {
	height: auto;
	@media (min-width: $screen-sm-max) {
		height: 300px;
	}

}