//menu styles
.navbar-default {
	margin-bottom: 0;
	min-height: auto;
	background-color: $white;
	border: 0;
	
	// position: relative;

	.navbar-nav {
		margin-left: 10px;
		margin-top: 15px;
		@media (min-width: $screen-sm-max) {
			margin-left: 25px;
		}
		> .active {
			> a {
			    color: $blue;
				background-color: transparent;

				&:before {
					content: "";
					position: absolute;
					bottom: -10px;
					left: 0;
					width: 15px;
					height: 5px;
					background: $blue;
				}
				&:hover,
				&:focus {
					color: $blue;
					background-color: transparent;
				}
			}
		} 
		> li{
			> a {
				margin: 0 15px;
			    padding: 0;
				font-size: 14px;
				&:hover,
				&:focus {
					color: $blue;
					background-color: transparent;
				}
				@media (max-width: $screen-sm-max) {
					margin: 0 10px 0 0;
				}
				@media (max-width: $screen-xs-max) {
					display: block;
					font-size: 16px;
					line-height: 20px;
					margin: 0 10px 20px 0;
				}
			}

		}
	}
	.lang-wrapper {
		margin: 0;
		padding: 0;

		height: 25px;

		position: absolute;
		top: 0;
		left: 60%;

		@include addTransform(-50%, 0);
 
		@media (min-width: $screen-xs-max) {
			@include addTransform(0, 0);
			right: 10px;
			left: inherit;
		}

		min-width: 102px;
		background: url("../img/lang-bg.png") 0 0 no-repeat;
		> li{
			position: relative;
			display: inline-block;
			> a {
				font-size: 14px;
				margin: 0 10px;
				color: rgba(225,225,225,0.7);
				&.active,
				&:hover,
				&:focus {
					color: $white;
				}
			}
			&:first-child {
				margin-left: 12px;
				&:after {
					content: "";

					position: absolute;
					top: 2px;
					right: 0;
					 
					width: 1px;
					height: 80%;

					background-color: rgba(225,225,225,0.5);
				}
			}
		}
	}
}

.navbar-brand {
	height: auto;
	img {
		max-width: 133px;
	}
}
