// embeded fonts
@font-face {
}


//French 
:lang(en) {
}

:lang(fr) {
}

p {
  line-height: 22px;
}

.contact {

  .secondary-info {

    h3 {
    	line-height: 44px;
    }
  }
}


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?dh6m3y');
  src:  url('../fonts/icomoon.eot?dh6m3y#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?dh6m3y') format('truetype'),
    url('../fonts/icomoon.woff?dh6m3y') format('woff'),
    url('../fonts/icomoon.svg?dh6m3y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e90b";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-bugs:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-google-plus:before {
  content: "\e903";
}
.icon-group-meeting:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-networking:before {
  content: "\e906";
}
.icon-presentation:before {
  content: "\e907";
}
.icon-tool:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-windows-8:before {
  content: "\e90a";
}


