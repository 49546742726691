// general carousel

.slide-wrapper {
    min-height: auto;
    position: relative;

    @media (min-width: $screen-sm-max) {
        
        min-height: 340px;
    }

    .wrapper {
        width: 80%;
    }
}

#prev-btn,
#next-btn {
    padding: 0;
    width: 40px;
    height: 44px;
    background-color: $white;
    
    outline: none;
    border: 0;
    
    position: absolute;
    top: 25%;
    @include addTransformY(-50%);

    &:before {
        font-size: 34px;
        color: $gray;
    }

    @media (min-width: $screen-xs-max) {
        width: 55px;
        height: 59px;
        top: 50%;
        @include addTransformY(-50%);
    }
}
#prev-btn{
    left: 0;
    
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; 
    
    @media (min-width: $screen-sm-max) {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; 

    }

    
}

#next-btn {
    right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
    @media (min-width: $screen-sm-max) {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

    }

    
}

.testlalalalal {
    background: red;
}

.custom-slider-wrapper {
    position: relative;
        width: 100%;
   
    .custom-slider {
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        max-width: 1170px;
        height: 300px;
        position: relative;
        @media (min-width: $screen-xs-max) {
            margin: 0 auto;
            width: 80%;
        }
        &:after {
            content: "";

            position: absolute;
            width: 100%;
            height: 1px;

            top: 50%;                       
            left: 0;
            transform: translateX(-100%);
            
            background-color: $white;
        }
        .custom-nav-list {
            width: 30%;
            float: left;
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            overflow: hidden;

            display: none;
            @media (min-width: $screen-xs-max) {
                display: block; 
                width: 40%;   
            }
            
            li {
                transition: margin-left 0.3s linear, opacity 0.3s linear;
                color: #ffffff;
                position: relative;
                padding-left: 40px;
                line-height: 27px;
                text-transform: capitalize;
                font-size: 12px;
                cursor: pointer;
                user-select: none;
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0px;
                    width: 26px;
                    height: 26px;
                    border: 1px solid #FFF;
                    border-radius: 50%;
                }
                &.offset0 {
                    opacity: 1;
                }
                &.offset10 {
                    margin-left: 10px;
                    opacity: 0.75;
                }
                &.offset20 {
                    margin-left: 20px;
                    opacity: 0.5
                }
                &.offset30 {
                    margin-left: 30px;
                    opacity: 0.25;
                }

                &.offset40 {
                    margin-left: 40px;
                    opacity: 0;
                }

                &.active {
                    line-height: 32px;
                    font-size: 15px;
                    &:before {
                        width: 31px;
                        height: 31px;
                    }
                    &:after {
                        content: "";
                        display: block;
                        width: 15px;
                        height: 16px;
                        position: absolute;
                        top: 8px;
                        left: 8px;
                        background-color: #FFF;
                        border-radius: 50%;
                    }
                }
            }
        }
        .custom-slides {
            width: 75%;
            float: none;
            margin: 0 auto;
            
            @media (min-width: $screen-xs-max) {
                float: left;
                width: 50%;
            }
            .custom-slide {
                display: none;
                &.active {
                    display: block;
                }
            }

            .slide-heading {
                font-size: 24px;
                margin-bottom: 25px;
            }

            p {
                color: $slide-gray;
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
        .custom-control {
            a {
                position: absolute;
                top: 48%;
                transform: translate(0,-50%);
            }
            .prev{
                left: 0;
            }
            .next {
                position: absolute;
                right: 0;
            }
        }
    }
}

